import React from "react";
import { useState } from "react";

const TermsAndConditionsModal = ({ setShowModal, setIsAcceptedTerms, handleSubscription, acceptable }) => {
  const [readContent, setReadContent] = useState(false)

  const acceptTerms = async () => {
    setShowModal(false);
    setIsAcceptedTerms(true);
    handleSubscription();
  };

  const cancelTerms = async () => {
    setShowModal(false);
    setIsAcceptedTerms(false);
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom & !readContent) {
      setReadContent(true)
    }
  }
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none h-screen">
        <div className="relative w-auto md:mx-auto mx-8 md:w-1/2 w-full">
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="md:mx-8 mx-4 mt-8 mb-4">
              <div className="mb-8 flex justify-between font-bold text-xl">
                <span className="text-white md:hidden">x</span>
                <span>{"AGREEMENT & CONSENT"}</span>
                <span
                  className="cursor-pointer"
                  onClick={() => setShowModal(false)}
                >
                  x
                </span>
              </div>

              <div className="md:my-4 my-8 flex flex-col gap-4">
                <div className="text-justify text-sm text-semibold break-words max-h-128 overflow-auto px-2"
                  onScroll={handleScroll}
                >
                  <div className="mb-4">
                    IN CONSIDERATION OF QuantFinancePrep, LLC (<span className="font-medium">“QFP”</span>) GRANTING ACCESS TO ITS SERVICES, AND
                    FOR OTHER GOOD AND VALUABLE CONSIDERATION, THE RECIEPT AND SUFFICENCY OF WHICH
                    YOU ACKNOWLEDGE, YOU ARE ENTERING INTO THIS AGREEMENT AND THE TERMS AND
                    CONDITONS SET FORTH BELOW.
                  </div>
                  <div className="font-medium mb-4">
                    BY HITTING “ACCEPT” BELOW, YOU ARE AGREEING TO THE FOLLOWING TERMS AND CONDITIONS
                    WHICH WILL FORM A BINDING CONTRACT BY YOU.
                  </div>
                  <ol className="list-decimal px-4">
                    <li className="mb-2">
                      You acknowledge that all fees paid by you are provided for access to the QFP content or
                      services, or for access to QFP’s chatrooms, discussion portals, comment areas, or other
                      platforms or other available discussion or messaging arena (the “QFP Platforms”) and
                      understand and agree that all such fees are non-refundable for any reason whatsoever.
                    </li>
                    <li className="mb-2">
                      You represent and agree that (i) you are at least 18 years of age, (ii) you are the individual you
                      represent yourself to be, (iii) all information provided by you or on your behalf is true, accurate,
                      complete, and free from any misleading representations or statements and (iv) you are legally
                      able to and are duly authorized to enter into this Agreement. You will promptly, fully, and
                      accurately respond to any inquiry by QFP regarding your information or any requests for
                      verification of same. In the event QFP suspects, in good faith, that any information provided is
                      not true, accurate, or complete, or in the event you fail to respond promptly, fully, and
                      accurately, QFP may at its discretion and option temporarily or permanently suspend your
                      account and/or access to QFP website/services without incurring any liability to you, and you
                      waive any claims relating to or arising from any such suspension or termination, including any
                      right to a return of any fees paid by you to QFP. You will be given a written notice of such action
                      within a reasonable time after such suspension.
                    </li>
                    <li className="mb-2">
                      QFP shall have the right to use any content or information you provide to QFP or post on or to a
                      QFP Platform or otherwise provide to or through QFP or its services, and you hereby provide to
                      QFP a worldwide royalty free license for the use of all such content, information and materials
                      so provided or posted. You also consent to the use by QFP to use and distribute information you
                      provide including your name, email, and other contact information.
                    </li>
                    <li className="mb-2">
                      You acknowledge and agree that all content, material and information provided by QFP to you
                      through the provision of its services is proprietary, and except as otherwise stated, the property
                      of QFP and shall not be used by you except for purposes expressly authorized by QFP and will
                      not be provided to or shared with any other person or entity without prior written and specific
                      approval from QFP. It is your duty to protect and keep confidential any information of QFP
                      provided to or accessed by you.
                    </li>
                    <li className="mb-2">
                      You agree that you will not engage in any download, copy, reproduce, store or distribution
                      (directly or indirectly) of any content provided though a QFP Service or on a QFP platform in any
                      form (including screenshots, printouts etc.) without express and specific prior written
                      permission from QFP.
                    </li>
                    <li className="mb-2">
                      You agree that you will not share or provide to any other person or entity any password, login or
                      other similar information enabling access to the QFP website, the QFP Platform or QFP services.
                      You will not share or misuse any promotion code, discount code or organization access code.
                    </li>
                    <li className="mb-2">
                      All QFP Platforms are for discussions relating to the services provided by QFP and for discussing
                      topics related to same, and you agree that you will limit your participation to such topics. You
                      further agree to behave in a professional manner in and on QFP Platforms. You agree that you
                      will not engage in any of the following behaviors or communications in connection with the QFP
                      website and in any QFP Platforms or in connection with any QFP Service, each of which are
                      expressly prohibited: bullying, harassing behavior, racist, bigoted, sexist or demeaningstatements, statements promoting or attacking gender identity or choices, political statements
                      or arguments, or any communications which by their nature demean, intimidate or devalue any
                      persons or groups. You agree that you will in all communications respect a person’s preferred
                      choice of reference pronouns.
                    </li>
                    <li className="mb-2">
                      You further represent that your participation in and with the QFP Platform or QFP Services does
                      not violate (i) any laws of your home jurisdiction or (ii) any obligation to or agreement you may
                      have to any other party (including but not limited to any company by whom you are employed
                      or with whom you affiliated or to whom you provide any services. You further agree that at no
                      time will you, in connection with any activities with or through the QFP Platforms or QFP
                      Services or platforms violate any such laws, obligations or agreements.
                    </li>
                    <li className="mb-2">
                      You agree that a breach of your representations or agreement set forth in this agreement (or an
                      allegation that you have violated any of the provisions of section 4 through 9, inclusive, above)
                      may, at QFP’s option lead to a temporary or permanent suspension of your account and access
                      to the QFP Platforms or QFP services without incurring any liability to you, and you waive any
                      claims relating to or arising from any such suspension or termination, including any right to a
                      return of any funds provided by you to QFP.
                    </li>
                    <li className="mb-2">
                      You acknowledge and agree that QFP is not responsible for any content (or the accuracy or
                      propriety of) any information or content posted by participants in any chatrooms or discussion
                      forums. You agree that you will promptly report to QFP any information or content which you
                      believe is inappropriate or which violates QFP policies.
                    </li>
                    <li className="mb-2">
                      As used herein, <span className="italic font-medium">“Confidential Information”</span> means, information concerning QFP’s business,
                      including, without limitation, QFP’s trade secrets, materials provided directly or indirectly by
                      QFP, databases and information contained therein, research, know how, all information on
                      market strategies and business plans. Confidential Information shall not include any portion of
                      the foregoing which (i) in its protected form is or becomes generally available to the public in
                      any manner or form through no fault of you, or (ii) is approved for disclosure or use through the
                      express written consent to you from of an authorized official of QFP.<br/><br/>
                      With respect to Confidential Information:<br/>
                      <ol className="list-[lower-latin] pl-4 mt-2">
                        <li className="mb-2">
                          You agree that all Confidential Information is, and shall be, the exclusive property of QFP.
                          You agree that you will not disclose any Confidential Information to any person or entity
                          without QFP’s express written consent. You agree that you will not use Confidential
                          Information for any purposes other than your own preparation for an interview.
                        </li>
                        <li className="mb-2">
                          You acknowledge and agree that QFP has a legitimate business interest in protecting
                          QFP’s Confidential Information. You further acknowledge and agree that QFP has
                          expended considerable effort, time and resources in developing and maintaining the
                          Confidential Information and that such information is considered by QFP to be an integral
                          part of its ability to continue as a successful business and that the secrecy and
                          confidentiality of QFP’s Propriety/Confidential Information is of utmost importance and
                          value to QFP.
                        </li>
                        <li className="mb-2">
                          You further acknowledge and agree that a breach of this Agreement (specifically including
                          but not limited to the provisions of Section 5 and of this Section 12) will cause QFP
                          irreparable harm and that it may seek injunctive relief in addition to all other damages
                          and remedies available in equity or at law.12) You understand that QFP is relying on this representation by you, and you intend for QFP to so
                          rely.
                        </li>
                      </ol>
                    </li>
                    <li className="mb-2">
                      This Agreement shall be binding upon and inure to the benefit of you, QFP, and each of their
                      respective successors and assigns, including any corporation with which, or into which, QFP may
                      be merged or which may succeed to QFP’s assets or business. Further, all rights given to, or
                      waivers or releases in favor of QFP run to the benefit of its affiliates and the equity owners,
                      officers, directors, and employees of QFP or its affiliates.
                    </li>
                    <li className="mb-2">
                      In case any provision of this Agreement shall be invalid, illegal or otherwise unenforceable, the
                      validity, legality and enforceability then (a) the provision shall be blue lined to the extent
                      necessary to make it enforceable while at the same time preserving as much as possible the
                      intent of the provision and (b) the remaining provisions shall in no way be affected or impaired
                      thereby.
                    </li>
                    <li className="mb-2">
                      No delay or omission by QFP in exercising any right under this Agreement will operate as a
                      waiver of that or any other right. Any waiver or consent given by QFP on any one occasion is
                      effective only in that specific instance and will not be construed as a bar to or waiver of any right
                      on any other occasion.
                    </li>
                    <li className="mb-2">
                      <div className="font-medium">
                        Choice of Law/Jurisdiction/Waiver of Jury Trial: THIS AGREEMENT WILL BE GOVERNED BY,
                        CONSTRUED, INTERPRETED, AND ITS VALIDITY DETERMINED, UNDER THE LAW OF THE STATE
                        OF NEW JERSEY. THE PARTIES AGREE THAT THE COURTS OF THE STATE OF NEW JERSEY HAVE
                        EXCLUSIVE JURISDICTION OVER ANY MATTER RELATING TO OR ARISING OUT OF THIS
                        AGREEMENT AND ANY DISPUTE REGARDING THE RELATIONSHIP BETWEEN YOU AND QFP,
                        AND EACH PARTY SUBMITS TO THE JURISDICTION OF THOSE COURTS. EACH PARTY WAIVES
                        ANY RIGHT TO HAVE A MATTER RELATING TO OR ARISING OUT OF THIS AGREEMENT TRIED TO
                        A JURY.
                      </div>
                    </li>
                    <li className="mb-2">
                      Prior Understandings: This Agreement contains the entire agreement with respect to the
                      subject matter of the Agreement, is intended as a final expression of such parties’ agreement
                      with respect to such terms as are included in this Agreement, is also intended as a complete and
                      exclusive statement of the terms of such agreement, and supersedes all negotiations,
                      stipulations, understandings, agreements, representations and warranties, if any, with respect
                      to such subject matter, which precede or accompany the execution of this Agreement. This
                      Agreement is a fully integrated agreement.
                    </li>
                    <li className="mb-2">
                      Waiver: Any waiver of default under this Agreement must be made in writing and shall not be a
                      waiver of any other default concerning the same or any other provision of this Agreement. No
                      delay or omission in the exercise of any right or remedy shall impair such right or remedy or be
                      constructed as a waiver. A consent to or approval of any act shall not be deemed to waive or
                      render unnecessary consent to or approval of any other or subsequent act.
                    </li>
                    <li className="mb-2">
                      You acknowledge and agree that QFP is providing a service, the success of which depends on
                      numerous factors outside QFP’s control, including but not limited to (a) abilities, background,
                      demeanor, and presentations by the relevant participant or applicant (b) the then current needs
                      of employers, the economic environment, and similar matters.
                      <div className="font-medium">
                        YOU ACKNOWLEDGE AND AGREE THAT QFP DOES NOT AND CANNOT GUARANTEE SUCCESS
                        OR ANY PARTICULAR OUTCOME FROM A USE OF ITS SERVICES.
                      </div>
                    </li>
                  </ol>
                  <div className="font-medium">
                    BY HTTING “ACCEPT” AND PROCEEDING, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE CAREFULLY
                    READ THIS AGREEMENT AND UNDERSTAND AND AGREE TO ALL OF THE PROVISIONS IN THIS
                    AGREEMENT.
                  </div>
                </div>
                {acceptable ? (
                  <div className="text-center">
                    <button
                      className="mx-auto w-32 my-2 mx-4 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer"
                      onClick={cancelTerms}
                    >
                      {"Cancel"}
                    </button>
                    <button
                      className={`mx-auto w-48 my-2 mx-4 py-2 rounded-full text-center 
                        text-semibold bg-green-g1 text-white 
                        ${readContent ? 'cursor-pointer' : 'opacity-70 cursor-not-allowed'}`}
                      onClick={acceptTerms}
                      disabled={!readContent}
                    >
                      {"Accept & Continue"}
                    </button>
                  </div>
                ) : ""}
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-95 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default TermsAndConditionsModal;
