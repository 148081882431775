import React, { useEffect, useState } from "react";
import { Header } from "../../shared";
import { MainContext } from "../../../App";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ConfirmationPage = () => {
  const [loginModal, setLoginModal] = React.useState(false);
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [payload, setPayload] = useState({
    token: "",
    username: ""
  })
  const [confirmationResponse, setConfirmationResponse] = useState({
    data: null,
    status: null
  })

  const navigate = useNavigate()
  const context = React.useContext(MainContext)
  let params = useParams()
  useEffect(() => {
    try {
      setPayload({ username: params.username, token: params.token })
    } catch (e) {
    }
  }, [])

  const headers = {
    'Content-Type': 'application/json',
  }

  useEffect(() => {
    async function confirmUser() {
      try {
        const { data, status } = await axios.get(`${process.env.REACT_APP_QA_API}/confirmation/${payload.token}/${payload.username}`, { headers })
        setConfirmationResponse({ data, status })
        if (status) {
          setLoginModal(true)
        }
      } catch (error) {
        navigate('/')
        console.log(error)
      }
    }
    if (payload.token !== "" && payload.username !== "") {
      confirmUser()
    }
  }, [payload])
  return (
    <>
      <div className="hidden md:block">
        <Header
          login={context.login}
          loginModal={loginModal}
          setLoginModal={setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
        />
      </div>
      <div className="m-8 text-center">
        {confirmationResponse.status === null ? (<p>Confirming...</p>) :
          confirmationResponse.status === 200 ?
            (<p>Successfully confirmed. Please log in.</p>) :
            (<p>Confirmation failed. Please double check your confirmation link.</p>)
        }
      </div>
    </>
  );
};

export default ConfirmationPage;
