import React from "react";

const Section = () => {
  return (
    <div className="mt-24">
      <video 
        className="m-auto w-full xl:w-2/3"
        controls
        autoPlay
        src={"https://betterprep-statistics-css-images.s3.amazonaws.com/video/QuantFinancePrep.mp4"}
        id=""
      />
    </div>
  );
};

export default Section;
