import React from "react";

const CancelSubscriptionConfirmModal = ({ setShowCancelSubscriptionModal, confirmCancelSubscription }) => {
  const cancelSubscriptionYes = async () => {
    setShowCancelSubscriptionModal(false);
    confirmCancelSubscription()
  };

  const cancelSubscriptionNo = async () => {
    setShowCancelSubscriptionModal(false);
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-x-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="md:w-96 md:mx-8 mx-4 mt-8">
              <div className="mb-8 flex justify-between font-bold text-xl">
                <span className="text-white md:hidden">x</span>
                <span>{"Cancel Subscription"}</span>
                <span
                  className="cursor-pointer"
                  onClick={() => setShowCancelSubscriptionModal(false)}
                >
                  x
                </span>
              </div>

              <div className="md:my-4 my-8 flex flex-col gap-4">
                <div className="text-sm text-semibold">
                  Are you sure?
                </div>
              </div>
              <div className="text-center">
                <button
                  className="mx-auto w-24 my-4 md:my-8 mx-4 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer"
                  onClick={cancelSubscriptionNo}
                >
                  {"No"}
                </button>
                <button
                  className="mx-auto w-24 my-4 md:my-8 mx-4 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer"
                  onClick={cancelSubscriptionYes}
                >
                  {"Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-95 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default CancelSubscriptionConfirmModal;
