import React from "react";
import { Header } from "../../shared";
import { MainContext } from "../../../App";
import { Link } from "react-router-dom";
import PageNotFound from "./images/_404.jpg";

const _404Page = () => {
  const [loginModal, setLoginModal] = React.useState(false);
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);

  const context =React.useContext(MainContext)
  return (
    <>
      <div className="hidden md:block">
        <Header
          login={context.login}
          loginModal={loginModal}
          setLoginModal={setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
        />
      </div>
      <div>
        <img src={PageNotFound} style={{maxWidth: "70%", margin: "auto"}}/>
        <div className="w-36 flex justify-center gap-2 bg-green-g1 py-2 rounded-full font-bold m-auto text-white">
          <Link to="/">Go to Home</Link>
        </div>
      </div>
    </>
  );
};

export default _404Page;
