import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Search from "./images/Search.svg";
import Comments from "./images/Comments.svg";
import Cross from "./images/Cross.svg";

import axios from 'axios';
import { MainContext } from "../../../../App";
import { useRef } from "react";

function Items({currentItems, searchQuery, setActiveUri, setCount, setTopicId}) {
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${token} `
  }
  const updateAccessHistory = async (topic) => {
    var topic_ = topic
    if (!topic_.access_history.includes(parseInt(userId))) {
      topic_.access_history = [...topic_.access_history, parseInt(userId)]
      try {
        await axios.put(
          `${process.env.REACT_APP_QA_API}/topic/${topic.id}`,
          topic_,
          { headers }
        );
      } catch (error) {
        console.log(error)
      }
    }
  }
  const handleTopicOpen = (topic, discussions) => {
    updateAccessHistory(topic)
    setActiveUri(topic.content_uri)
    setCount(discussions.length)
    setTopicId(topic.id)
  }
  return (
    <div className="py-6 text-gray-g1 flex flex-col gap-1">
    {currentItems && currentItems
      .filter((ele) => ele.topic.title.toLowerCase().includes(searchQuery.toLowerCase()))
      .map(({ topic, discussions }, index) => (
        <div
          className="py-2 px-3 rounded bg-gray-g5 flex justify-between font-bold cursor-pointer hover:shadow"
          key={index}
          onClick={() => handleTopicOpen(topic, discussions)}
        >
          {topic.access_history.includes(parseInt(userId)) ? (
            <span className="text-purple-700 font-verdana text-xl font-normal underline">
              {topic.title.charAt(0).toUpperCase()}
              {topic.title.slice(1)}
            </span>
          ) : (
            <span className="text-blue-1 font-verdana text-xl font-normal underline">
              {topic.title.charAt(0).toUpperCase()}
              {topic.title.slice(1)}
            </span>
          )}
          
          <Link to={`/discussions?topic=${topic.id}`} key={`theme-data-${index}`}>
            <span className="flex gap-2">
              <img src={Comments} alt="Comments" className="w-4" />
              {discussions.length}
            </span>
          </Link>
        </div>
      ))}
    </div>
  )
}

function ThemeItems({ themeData, searchQuery, setActiveUri, setCount, setTopicId }) {
  return (
    <>
      <Items currentItems={themeData} searchQuery={searchQuery} setActiveUri={setActiveUri} setCount={setCount} setTopicId={setTopicId} />
    </>
  );
}

function capitalizeFirstLetter(str) {
  str = str.toLowerCase()
  const capitalized = str.replace(/^./, str[0].toUpperCase());
  return capitalized;
}

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window
  return {
    width,
    height
  }
}

const Statistics = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchBar, setSearchBar] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [themeData, setThemeData] = useState([])
  const [primaryTopics, setPrimaryTopics] = useState([])
  const [secondaryTopics, setSecondaryTopics] = useState([])
  const [pythonConcepts, setPythonConcepts] = useState([])
  const [algorithms, setAlgorithms] = useState([])
  const [stochasticCalculus, setStochasticCalculus] = useState([])
  const [assetManagement, setAssetManagement] = useState([])
  const [factorModels, setFactorModels] = useState([])
  const [statConcepts, setStatConcepts] = useState([])
  const [mlConcepts, setMlConcepts] = useState([])
  const [topicId, setTopicId] = useState('')
  const [count, setCount] = useState(0)
  const topicType = pathname.split('/')[1].toUpperCase()
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [companies, setCompanies] = useState([])
  const [filterVaule, setFilterValue] = useState('')

  const context =React.useContext(MainContext)

  const loadCompanies = async () => {
    const token = localStorage.getItem('token')
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token} `
    }
    if (token) {
      const { data } = await axios.get(`${process.env.REACT_APP_QA_API}/topic/company/`, { headers });
      setCompanies(data)
    }
  }

  const apiCall = async (filterVaule_ = '') => {
    const token = localStorage.getItem('token')
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token} `
    }
    try {
      setLoading(true)
      if (token) {
        const { data } = await axios.get(`${process.env.REACT_APP_QA_API}/topic/${topicType}?filter=${filterVaule_}`, { headers })
        setLoading(false)
        setThemeData(data)
        const primaryTopics_ = data.filter((ele) => ele.topic.type === 'Index')
        setPrimaryTopics(primaryTopics_)
        const secondaryTopics_ = data.filter((ele) => ele.topic.type === 'Questions')
        setSecondaryTopics(secondaryTopics_)
        const pythonConcepts_ = data.filter((ele) => ele.topic.type === 'Python concepts')
        setPythonConcepts(pythonConcepts_)
        const algorithms_ = data.filter((ele) => ele.topic.type === 'Algorithms')
        setAlgorithms(algorithms_)
        const stochasticCalculus_ = data.filter((ele) => ele.topic.type === 'Stochastic calculus')
        setStochasticCalculus(stochasticCalculus_)
        const assetManagement_ = data.filter((ele) => ele.topic.type === 'Asset management')
        setAssetManagement(assetManagement_)
        const factorModels_ = data.filter((ele) => ele.topic.type === 'Factor models')
        setFactorModels(factorModels_)
        const statConcepts_ = data.filter((ele) => ele.topic.type === 'Statistics concepts')
        setStatConcepts(statConcepts_)
        const mlConcepts_ = data.filter((ele) => ele.topic.type === 'Machine Learning concepts')
        setMlConcepts(mlConcepts_)

        if (data.length > 0) {
          let ids = ''
          data.forEach(item => {
            ids += item.topic.id.toString() + ','
          })
          setTopicId(ids)
        }
      } else {
        navigate('/')
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
      if (error.response.status === 401) {
        context.setIsLoggedIn(false)
        localStorage.clear()
        navigate('/')
      }
      setError('Some error occured. Please reload your page.')
    }
  }
  useEffect(() => {
    setTopicId(null)
    context.setActiveUri(false)
    setFilterValue('')
    apiCall()
  }, [pathname])
  
  const params = new URLSearchParams(window.location.search)
  const topicId_ = params.get("topic")

  useEffect(() => {
    if (context.activeUri === "" || context.activeUri === false) {
      apiCall()

      let count_ = 0
      themeData.forEach((el) => { count_ += el.discussions.length })
      setCount(count_)

      if (topicId_ !== null) {
        const topic_ = themeData.filter((ele) => ele.topic.id === Number(topicId_))
        if (topic_.length > 0) {
          context.setActiveUri(topic_[0].topic.content_uri)
          setCount(topic_[0].discussions.length)
        }
      }
    }
  }, [context.activeUri, topicId_])

  useEffect(() => {
    loadCompanies()
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () =>  window.removeEventListener('resize', handleResize)
  }, [])

  const handleFilterChange = (event) =>  {
    setFilterValue(event.target.value)
    apiCall(event.target.value)
  }

  return (
    <>
      <div className="w-full flex flex-col md:justify-between p-3 md:p-6">
        {loading ? <div className="h-screen text-white">Loading...</div> : <div>
          <div className="flex justify-between items-center">
            <span className="text-2xl font-bold text-gray-g1 cursor-pointer" onClick={() => context.setActiveUri('')}>{topicType === 'ML' ? 'Machine Learning (ML)' : capitalizeFirstLetter(topicType)}</span>
            <span
              className={`h-10 bg-gray-g5 p-3 rounded-full flex items-center gap-2 ${searchBar && "ring-1 ring-green-g1"
                }`}
            >
              {searchBar && (
                <input
                  type="text"
                  className="px-1 md:px-2 placeholder-gray-g9 focus:outline-none bg-gray-g5"
                  placeholder="Type Here..."
                  value={searchQuery}
                  onChange={(e) => {
                    context.setActiveUri('')
                    setSearchQuery(e.target.value)
                  }}
                />
              )}
              {searchQuery.length > 0 ? (
                <div
                  className="cursor-pointer"
                  onClick={() => setSearchQuery("")}
                >
                  <img src={Cross} alt='' />
                </div>
              ) : (
                <img
                  src={Search}
                  alt="Search"
                  className="w-4 cursor-pointer"
                  onClick={() => setSearchBar(!searchBar)}
                />
              )}
            </span>
          </div>
          {searchQuery.length > 0 && (
            <div className="mt-6">
              <span className="text-gray-g12">Result</span>
              <span className="text-black ml-2 font-bold">"{searchQuery}"</span>
            </div>
          )}
          <div className="flex justify-between">
            {context.activeUri ? (<div></div>) : (
              <div className="m-2 flex">
                <div className="px-6 py-2 bg-green-g1 rounded-full font-semibold text-white">Filter by company</div>
                <select className="filter-select mx-2 px-4 py-1 pr-8 rounded-full outline-none cursor-pointer border border-green-500 focus:ring-green-500 focus:border-green-500 focus-visible:border-green-500" value={filterVaule} onChange={handleFilterChange}>
                  <option value="">-</option>
                  {companies.map(({name, value}, index) => (
                    <option value={`${value}`}  key={`select-option-${index}`}>{name}</option>
                  ))}
                </select>
              </div>
            )}

            {topicId && <Link to={`/discussions?topic=${topicId_ === null ? topicId : topicId_}`}>
              <div
                className={`mx-2 my-2 z-3 md:flex justify-end items-center font-bold cursor-pointer hidden md:right-4 z-1`}
              >
                <div className="mx-2 flex justify-end items-center font-bold cursor-pointer">
                  <div className="py-2 px-4 rounded-full shadow-lg bg-gray-200">
                    <span>Discussion</span>
                    <span className="ml-4 px-4 py-1 text-white bg-green-g1 rounded-full">{count}</span>
                  </div>
                </div>
              </div>
              <div
                className={`mx-2 z-3 flex justify-center left-0 right-0 items-center font-bold cursor-pointer md:hidden`}
              >
                <div className="mx-2 flex justify-end items-center font-bold cursor-pointer py-2">
                  <div className="py-2 px-4 rounded-full shadow-lg bg-gray-200">
                    <span>Discussion</span>
                    <span className="ml-4 px-4 py-1 text-white bg-green-g1 rounded-full">{count}</span>
                  </div>
                </div>
              </div>
            </Link>}
          </div>
          {context.activeUri ? <iframe src={context.activeUri} title={context.activeUri} width='100%' className="h-[75vh] mt-3" /> : 
            <div className="h-[85vh] overflow-auto">
              {themeData && themeData.filter((ele) => ele.topic.title.toLowerCase().includes(searchQuery.toLowerCase())).length === 0 ? 
                <div className="mt-4">No result found.</div> : ""}
              <ThemeItems themeData={primaryTopics} searchQuery={searchQuery} setActiveUri={context.setActiveUri} setCount={setCount} setTopicId={setTopicId}/>
              {pythonConcepts.filter((ele) => ele.topic.title.toLowerCase().includes(searchQuery.toLowerCase())).length > 0 ? 
                <>
                  <div className="text-2xl">Python concepts</div>
                  <ThemeItems themeData={pythonConcepts} searchQuery={searchQuery} setActiveUri={context.setActiveUri} setCount={setCount} setTopicId={setTopicId}/>
                </> : ''}
              {statConcepts.filter((ele) => ele.topic.title.toLowerCase().includes(searchQuery.toLowerCase())).length > 0 ? 
                <>
                  <div className="text-2xl">Statistics concepts</div>
                  <ThemeItems themeData={statConcepts} searchQuery={searchQuery} setActiveUri={context.setActiveUri} setCount={setCount} setTopicId={setTopicId}/>
                </> : ''}
              {mlConcepts.filter((ele) => ele.topic.title.toLowerCase().includes(searchQuery.toLowerCase())).length > 0 ? 
                <>
                  <div className="text-2xl">Machine Learning concepts</div>
                  <ThemeItems themeData={mlConcepts} searchQuery={searchQuery} setActiveUri={context.setActiveUri} setCount={setCount} setTopicId={setTopicId}/>
                </> : ''}
              {algorithms.filter((ele) => ele.topic.title.toLowerCase().includes(searchQuery.toLowerCase())).length > 0 ? 
                <>
                  <div className="text-2xl">Algorithms</div>
                  <ThemeItems themeData={algorithms} searchQuery={searchQuery} setActiveUri={context.setActiveUri} setCount={setCount} setTopicId={setTopicId}/>
                </> : ''}
              {stochasticCalculus.filter((ele) => ele.topic.title.toLowerCase().includes(searchQuery.toLowerCase())).length > 0 ? 
                <>
                  <div className="text-2xl">{"Stochastic calculus (Sell side)"}</div>
                  <ThemeItems themeData={stochasticCalculus} searchQuery={searchQuery} setActiveUri={context.setActiveUri} setCount={setCount} setTopicId={setTopicId}/>
                </> : ''}
              {assetManagement.filter((ele) => ele.topic.title.toLowerCase().includes(searchQuery.toLowerCase())).length > 0 ? 
                <>
                  <div className="text-2xl">{"Asset management (Buy side)"}</div>
                  <ThemeItems themeData={assetManagement} searchQuery={searchQuery} setActiveUri={context.setActiveUri} setCount={setCount} setTopicId={setTopicId}/>
                </> : ''}
              {factorModels.filter((ele) => ele.topic.title.toLowerCase().includes(searchQuery.toLowerCase())).length > 0 ? 
                <>
                  <div className="text-2xl">{"Factor models (Risk / buy side)"}</div>
                  <ThemeItems themeData={factorModels} searchQuery={searchQuery} setActiveUri={context.setActiveUri} setCount={setCount} setTopicId={setTopicId}/>
                </> : ''}
              {secondaryTopics.filter((ele) => ele.topic.title.toLowerCase().includes(searchQuery.toLowerCase())).length > 0 ? 
                <>
                  <div className="text-2xl">{"Popular " + (topicType === 'ML' ? topicType : topicType.toLowerCase()) + " questions in quant job interview"}</div>
                  <ThemeItems themeData={secondaryTopics} searchQuery={searchQuery} setActiveUri={context.setActiveUri} setCount={setCount} setTopicId={setTopicId}/>
                </> : ''}
            </div>
          }
        </div>}
        {error && <div>Some error occured. Please try again.</div>}

        

      </div>
    </>
  );
};

export default Statistics;