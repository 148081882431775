import React, { useEffect, useState } from "react";
import { Header } from "../../shared";
import { MainContext } from "../../../App";
import axios from "axios";
import { useParams } from "react-router-dom";
import Eye from "./images/Eye.svg";

const ResetPasswordPage = () => {
  const [loginModal, setLoginModal] = React.useState(false);
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [payload, setPayload] = useState({
    token: "",
    username: ""
  })
  const [formData, setFormData] = useState({"password": ""})
  const [resetPasswordResponse, setResetPasswordResponse] = useState({
    data: null,
    status: null
  })
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')

  const context =React.useContext(MainContext)
  let params = useParams()
  useEffect(() => {
    try {
      setPayload({username: params.username, token: params.token})
    } catch (e) {
    }
  }, [])

  const headers = {
    'Content-Type': 'application/json',
  }

  const handleResetPassword = () => {
    if (formData.password.length < 8) {
      setError('Password must contain at least 8 characters.')
      return
    }
    async function confirmUser() {
      try{
        const { data, status } = await axios.post(`${process.env.REACT_APP_QA_API}/reset-password/${payload.token}/${payload.username}`, formData, { headers })
        setResetPasswordResponse({data, status})
        if (status) {
          setLoginModal(true)
        }
      } catch (e) {
        setResetPasswordResponse({data: e.response.data, status: e.response.status})
      }
    }
    if (payload.token !== "" && payload.username !== "" && formData['password'] !== "") {
      confirmUser()
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, ['password']: e.target.value })
  }
  return (
    <>
      <div className="hidden md:block">
        <Header
          login={context.login}
          loginModal={loginModal}
          setLoginModal={setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
        />
      </div>
      <div className="m-8 text-center">
        {resetPasswordResponse.status === null ? 
        (<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-x-0 z-50 outline-none focus:outline-none">
          <div className="md:my-4 my-8 flex flex-col gap-4">
            <div className="text-sm text-semibold">Please enter new password.</div>
            <div className="flex">
              <input
              className={`w-full pl-4 pr-12 py-2 ring-1 rounded-full placeholder-text-blue-b1 focus:outline-none focus:ring-1 ring-gray-g6 focus:ring-green-g1 `}
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              name='password'
              value={formData.password}
              onChange={handleChange}
              />
              <img src={Eye} alt="ShowPassword" className="w-6 -ml-10 cursor-pointer" onClick={() => setShowPassword(!showPassword)} />
            </div>
            {error && <div className="text-red-r1 text-sm">{error}</div>}
            <div className="text-center">
              <button className="mx-auto w-32 my-8 md:my-4 py-2 rounded-full text-center text-semibold bg-green-g1 text-white cursor-pointer" onClick={handleResetPassword}>
                {"Reset Password"}
              </button>
            </div>
          </div>
        </div>) : 
        resetPasswordResponse.status === 200 ? 
        (<p>Successfully reset password. Please log in.</p>) : resetPasswordResponse.status === 400 ?
        (<p>Reset Password failed. Please double check your reset password link.</p>) : ""}
      </div>
    </>
  );
};

export default ResetPasswordPage;
