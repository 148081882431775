import React from "react";
import questionsandanswers from "./images/questionsanswers.svg";
import recruiters from "./images/recruiters.svg";
import toolkit from "./images/toolkit.svg";
import Video from "./images/video.png";
import Officeworkers from "./images/OfficeWorkers.png";

const OurBenefit = () => {

  const CheckLine = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="rgba(0,176,97,1)"/></svg>

  return (
    <div>
        <div className="relative md:bg-white text-left text-justify">
          <div className="mx-16 mb-16">
            A quant finance job in your preferred company can be the launchpad for a highly rewarding career, and can significantly boost your overall earning potential. Whether you are a student starting out or an entry-level to a mid-senior professional in quant finance looking for a change, it is very important to be with the companies that further your career goals.
          </div>
          <div className="mx-16 my-16">
            Most candidates focus on getting an interview call, however <strong>being prepared</strong> for the interview is ultimately the key differentiator. Recruiters can assist in getting an interview but not in preparing you for the interview. This is where QuantFinancePrep adds distinctive value to your efforts, especially when you are <strong>hard pressed for time</strong>.
          </div>
          <div className="mx-16 my-16">
            Our accomplished quant finance team brings extensive hiring experience in professional organizations ranging from top hedge-funds to bulge-bracket banks and this has enabled us to identify core themes that hiring managers consistently look to test candidates on during the interview process. We start off with the <strong>core themes</strong>, explain the <strong>fundamentals</strong>, identify the types of <strong>questions and issues within those themes</strong> that are <strong>currently in vogue</strong>, share possible <strong>variations</strong> of these questions, and provide <strong>detailed solutions</strong> including fully functional <strong>Python code</strong>.
          </div>
          <div className="mx-16 my-16">
            It is important to bear in perspective that quant finance interviewers come from four distinct backgrounds – <strong>Finance</strong>, <strong>Statistics</strong>, <strong>Coding</strong>, and <strong>Machine Learning</strong>. While you may not be expected to be equally proficient in all four, deep domain expertise in your chosen area supplemented by a high level of working familiarity is what sets the successful candidate apart in a pool of extremely competitive candidates. This <strong>interoperability</strong> is increasingly what hiring managers are looking for in the candidates.
          </div>
          <div className="mx-16 my-16">
            QuantFinancePrep is a <strong>one stop shop</strong> that prepares candidates in all these four areas of finance, statistics, coding and machine learning. We help you brush up your preparedness in your core area and do a deep dive, while providing you with sufficient content to explore further in the related areas as your time and interest permits. 
          </div>
        </div>
        <div className="w-full py-8 bg-gray-g2">
          <div className="text-2xl font-bold text-center md:pt-16">Why QuantFinancePrep?</div>
          <div className="mx-16 my-16 flex gap-8 md:gap-8 flex-col md:flex-row justify-between">
            <div className="w-11/12 md:w-1/3 flex flex-col items-center text-left text-justify">
              <img src={toolkit} alt="toolkit" className="md:h-80" />
              <div className="my-2 font-bold text-lg ">Comprehensive, robust and frequently updated toolkit:</div>
              <div className="my-2 text-sm">
                You’ll learn what hiring managers are looking for and what is expected of you in interviews
              </div>
              <div className="my-2 text-sm">
                We start off with the <strong>core themes</strong>, explain the <strong>fundamentals</strong>, identify the types of <strong>questions and issues within those themes</strong> that are <strong>currently in vogue</strong>, share possible <strong>variations</strong> of these questions, and provide <strong>detailed solutions</strong> including fully functional <strong>Python code</strong>.
              </div>
            </div>
            <div className="w-11/12 md:w-1/3 flex flex-col items-center text-left text-justify">
              <img src={questionsandanswers} alt="questionsandanswers" className="md:h-80" />
              <div className="my-2 font-bold text-lg ">Community portal:</div>
              <div className="my-2 text-sm">
                Through our portal, you can clarify any doubts, share your interview ideas and experiences, and find a community of quant finance professionals that supports your career progression
              </div>
            </div>
            <div className="w-11/12 md:w-1/3 flex flex-col items-center text-left text-justify">
              <img src={recruiters} alt="recruiters" className="md:h-80" />
              <div className="my-2 font-bold text-lg ">Exposure to top finance recruiters:</div>
              <div className="my-2 text-sm">
                We have partnerships with some of the top quant finance recruiters with active mandates, who match the users’ expertise, experience and preferences with the available quant jobs
              </div>
            </div>
          </div>

          <div className="md:mt-16">
            <div className="mx-16 my-16 text-left text-justify">
              Our website content including but not limited to themes, concepts, analysis, questions, comments, and suggested answers address current interview topics for investment banks, hedge funds and fintech companies. The content is revised and updated on a regular basis and is intended to be a career progression tool which could be particularly helpful in preparing applicants for quant finance job interviews.
            </div>
          </div>
        </div>
    </div>
  );
};

export default OurBenefit;



