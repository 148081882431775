import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import leftArrow from "./images/leftArrow.svg";
import back from "./images/back.svg";
import curvedArrow from "./images/curvedArrow.svg";
import { MainContext } from "../../../../App";

const Discussionv = () => {
  const { vantageId, discussionId } = useParams();
  const navigate = useNavigate();
  const [discussion, setDiscussion] = useState(false);
  const [answer_, setAnswer_] = useState(null)
  const [comment, setComment] = useState("");
  const [submittedComment, setSubmittedComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingAnswer, setLoadingAnswer] = useState(false);

  const [error, setError] = useState("");
  const [discussionData, setDiscussionData] = useState([]);
  const [vantageData, setVantageData] = useState([]);
  const [activeParentId, setactiveParentId] = useState(null);
  const [activeChildId, setActiveChildId] = useState(null);

  const [childAnswers, setChildAnswers] = useState([]);
  const [answerComment, setAnswerComment] = useState("");
  const [isEditComment, setIsEditComment] = useState(false);
  const [isEditDiscussion, setIsEditDiscussion] = useState(false);
  const [isEditAnswer, setIsEditAnswer] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [childSelectedFile, setChildSelectedFile] = useState(null);
  const [selectedEditFile, setSelectedEditFile] = useState(null);
  const [answerIdInEdit, setAnswerIdInEdit] = useState(null);

  const context =React.useContext(MainContext)
  const [checked, setChecked] = useState(false)

  const token = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("is_admin") === 'true' ? true : false;
  const userId = localStorage.getItem("userId")

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token} `,
  };

  const apiCall = async () => {
    try {
      setLoading(true);
      if (token) {
        const { data: discuss } = await axios.get(
          `${process.env.REACT_APP_QA_API}/discussion/${discussionId}`,
          { headers }
        );
        setDiscussionData(discuss);
        setDiscussion(discuss[0].discussion)
        setChecked(discuss[0].discussion.notify_block_user.includes(Number(userId)))
        setactiveParentId(discuss[0].answers.length > 0 ? discuss[0].answers[discuss[0].answers.length - 1].answer.id : null);
        const { data: vantage } = await axios.get(
          `${process.env.REACT_APP_QA_API}/vantage_/${vantageId}`,
          { headers }
        );
        setVantageData(vantage);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error.response.status === 401) {
        context.setIsLoggedIn(false)
        localStorage.clear()
        navigate('/')
      }
      setError("Some error occured. Please reload your page.");
    }
  };

  const getChildAnswers = async () => {
    try {
      setLoadingAnswer(true);
      if (token) {
        if (activeParentId != null) {
          const { data } = await axios.get(
            `${process.env.REACT_APP_QA_API}/discussion/answer/${activeParentId}`,
            { headers }
          );
          setChildAnswers(data[0].child_answers);
        }
        setLoadingAnswer(false);
      }
    } catch (error) {
      setLoadingAnswer(false);
      console.error(error);
      if (error.response.status === 401) {
        context.setIsLoggedIn(false)
        localStorage.clear()
        navigate('/')
      }
      setError("Some error occured. Please reload your page.");
    }
  };

  const handleAnswerSubmission = async (e) => {
    if ((e.keyCode === 13) && (e.ctrlKey === true)) {
      e.preventDefault();
      if (comment) {
        const formData = {
          content: comment,
          discussion: +discussionId,
        };
        try {
          await axios.post(
            `${process.env.REACT_APP_QA_API}/discussion/answer/`,
            formData,
            { headers }
          );
          setComment("");
          apiCall();
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleAnswerSubmissionByButton = async () => {
    if (comment) {
      const formData = new FormData()
      formData.append('content', comment)
      formData.append('discussion', +discussionId)
      if (selectedFile !== null) {
        formData.append('file', selectedFile)
      }
      try {
        const dataa = await axios.post(
          `${process.env.REACT_APP_QA_API}/discussion/answer/`,
          formData,
          { headers }
        );
        setSelectedFile(null)
        setComment("");
        apiCall();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChildAnswerSubmission = async (e) => {
    if (e.keyCode === 13 && e.ctrlKey === true) {
      e.preventDefault();
      if (answerComment) {
        try {
          if (isEditComment) {
            const formData = {
              content: answerComment,
              discussion: +discussionId,
              parent_answer: activeParentId
            };
            await axios.put(
              `${process.env.REACT_APP_QA_API}/discussion/answer/${activeChildId}`,
              formData,
              { headers }
            );
            setIsEditComment(false)
          } else {
            const formData = {
              content: answerComment,
              discussion: +discussionId,
              parent_answer: activeParentId,
            };
            await axios.post(
              `${process.env.REACT_APP_QA_API}/discussion/answer/`,
              formData,
              { headers }
            );
          }
          setAnswerComment("");
          getChildAnswers();
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleChildAnswerSubmissionByButton = async () => {
    if (answerComment) {
      try {
        const formData = new FormData()
        formData.append('content', answerComment)
        formData.append('discussion', +discussionId)
        formData.append('parent_answer', activeParentId)
        if (childSelectedFile !== null) {
          formData.append('file', childSelectedFile)
        }
        if (isEditComment) {
          await axios.put(
            `${process.env.REACT_APP_QA_API}/discussion/answer/${activeChildId}`,
            formData,
            { headers }
          );
          setIsEditComment(false)
        } else {
          await axios.post(
            `${process.env.REACT_APP_QA_API}/discussion/answer/`,
            formData,
            { headers }
          );
        }
        setAnswerComment("");
        getChildAnswers();
        setChildSelectedFile(null)
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSubAnswerDelete = async (id) => {
    try {
      setLoadingAnswer(true);
      await axios.delete(
        `${process.env.REACT_APP_QA_API}/discussion/answer/${id}`,
        { headers }
      );
      setLoadingAnswer(false);
      setAnswerComment("");
      getChildAnswers()
    } catch (error) {
      setLoadingAnswer(false);
      console.error(error);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    getChildAnswers();
  }, [activeParentId]);

  const activeUserEmail = localStorage.getItem('email')

  const handleDiscussionChange = () => {
    setIsEditDiscussion(true)
  }

  const handleDiscussionDelete = () => {
  }

  const handleDiscussionChangeSave = async () => {
    setIsEditDiscussion(false)
    try {
      const { data, status } = await axios.put(
        `${process.env.REACT_APP_QA_API}/answer/${discussion.id}`,
        discussion,
        { headers }
      );
      if (status === 200) {
        console.log('success')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDiscussionChangeCancel = () => {
    setIsEditDiscussion(false)
  }

  const handleAnswerChange = (answer) => {
    setAnswer_(answer)
    // setIsEditAnswer(true)
    setAnswerIdInEdit(answer.id)
  }

  const handleAnswerDelete = async (id) => {
    try {
      setLoadingAnswer(true);
      await axios.delete(
        `${process.env.REACT_APP_QA_API}/discussion/answer/${id}`,
        { headers }
      );
      apiCall();
      setLoadingAnswer(false);
    } catch (error) {
      setLoadingAnswer(false);
      console.error(error);
    }
  };

  const handleAnswerChangeSave = async () => {
    // setIsEditAnswer(false)
    const formData = new FormData()
    formData.append('content', answer_.content)
    formData.append('discussion', answer_.discussion)
    if (answer_.parent_answer) {
      formData.append('parent_answer', answer_.parent_answer)
    }
    if (selectedEditFile !== null) {
      formData.append('file', selectedEditFile)
    }
    try {
      const { data, status } = await axios.put(
        `${process.env.REACT_APP_QA_API}/discussion/answer/${answer_.id}`,
        formData,
        { headers }
      );
      if (status === 200) {
        console.log('success')
        apiCall()
      }
    } catch (error) {
      console.log(error)
    }
    setAnswerIdInEdit(null)
    setSelectedEditFile(null)
  }

  const handleAnswerChangeCancel = () => {
    // setIsEditAnswer(false)
    setAnswerIdInEdit(null)
  }

  const onBlockNotificationEventChange = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_QA_API}/discussion/${discussion.id}`,
        {'notify_block_user': checked === false ? [...discussion.notify_block_user, Number(userId)] : discussion.notify_block_user.filter(uId => uId !== Number(userId))},
        { headers }
      );
      setChecked(!checked);
    } catch (error) {
      console.error(error);
    }
  }

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  }

  const handleChildFileSelect = (event) => {
    setChildSelectedFile(event.target.files[0]);
  }

  const handleEditFileSelect = (event) => {
    setSelectedEditFile(event.target.files[0])
  }

  const handleDownload = (filePath) => {
    // const fileUrl = `${process.env.REACT_APP_QA_API}${filePath}`
    const fileUrl = `${filePath}`
    const fileName = filePath.split('/uploads/')[1]
    fetch(fileUrl, {
      headers: {
        Origin: window.location.origin
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.target = '_blank';

        link.click();

        URL.revokeObjectURL(url);
        link.remove();
      });
  }

  return (
    <>
      {loading && <div
      className={`w-full h-screen flex flex-col px-2 py-3 md:py-3 md:px-6 overflow-y-scroll bg-gray-g1 ${discussion
        ? "gap-2 md:bg-gray-g1"
        : "gap-6 md:gap-12 "
        }`}
      >Loading...</div>}
      {!loading && (
        <div
          className={`w-full h-screen flex flex-col px-2 py-3 md:py-3 md:px-6 overflow-y-scroll bg-gray-g1 ${discussion
            ? "gap-2 md:bg-gray-g1"
            : "gap-6 md:gap-12 "
            }`}
        >
          {vantageData.length > 0 && (
            <div
              className={`flex items-center gap-2 md:gap-4 text-xl font-bold bg-gray-g1 text-white ${discussion && "hidden md:flex"
                }`}
            >
              <Link to={`/${vantageData[0]?.vantage?.category.toLowerCase()}`}>
                <img
                  src={leftArrow}
                  alt="left"
                  className="p-1 bg-white hover:bg-gray-g10 rounded-full"
                />
              </Link>
              {vantageData[0]?.vantage?.category}
            </div>
          )}

          <div className="mx-auto mb-4 w-full h-full flex flex-col gap-6 py-4 rounded text-white bg-gray-g1">
            <div className="flex items-center">
              <img
                src={back}
                alt=""
                className="w-12 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <span className="font-bold text-lg">
                {vantageData[0]?.vantage?.title}
              </span>
            </div>
            {discussionData.map(({ answers, creator }, index) => (
              <div key={`discussion-ans-data-${index}`}>
                <div className="py-4 px-4 flex flex-col gap-4 rounded-lg bg-gray-g8">
                  <div className="flex justify-between">
                    <div className="flex gap-4 items-center">
                      {/* <img src={Profile} alt="Profile" className="w-8" /> */}
                      <div className="font-bold">{ creator.username }</div>
                      <div className="text-gray-g11">
                        {moment(discussion.created_at).toLocaleString()}
                      </div>
                    </div>
                    {isAdmin ? (
                      <div className="flex gap-4 items-center text-sm">
                        {isEditDiscussion ? (
                          <>
                            <div className="text-green-g1 cursor-pointer" onClick={() => {
                              handleDiscussionChangeSave()
                            }}>
                              Save
                            </div>
                            <div className="text-red-r1 cursor-pointer" onClick={() => handleDiscussionChangeCancel()}>
                              Cancel
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-green-g1 cursor-pointer" onClick={() => {
                              handleDiscussionChange()
                            }}>
                              Edit
                            </div>
                            {/* <div className="text-red-r1 cursor-pointer" onClick={() => handleDiscussionDelete()}>
                              Delete
                            </div> */}
                          </>
                        )}
                      </div>
                    ) : (
                      activeUserEmail === discussion.creator.email && <div className="flex gap-4 items-center text-sm">
                        {isEditDiscussion ? (
                          <>
                            <div className="text-green-g1 cursor-pointer" onClick={() => {
                              handleDiscussionChangeSave()
                            }}>
                              Save
                            </div>
                            <div className="text-red-r1 cursor-pointer" onClick={() => handleDiscussionChangeCancel()}>
                              Cancel
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-green-g1 cursor-pointer" onClick={() => {
                              handleDiscussionChange()
                            }}>
                              Edit
                            </div>
                            {/* <div className="text-red-r1 cursor-pointer" onClick={() => handleDiscussionDelete()}>
                              Delete
                            </div> */}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="justify-content text-sm font-bold">
                    {isEditDiscussion === true ? (
                      <textarea
                        placeholder="Type Discussion here..."
                        className="py-4 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-lg w-full my-4 bg-gray-g8"
                        value={discussion.content}
                        onChange={(e) => setDiscussion({...discussion, content: e.target.value})}
                        rows={discussion.content.split('\n').length > 10 ? 10 : discussion.content.split('\n').length }
                      />
                    ) : (
                      <textarea
                        className="py-4 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-lg w-full my-4 bg-gray-g8"
                        value={discussion.content}
                        disabled={true}
                        rows={discussion.content.split('\n').length > 10 ? 10 : discussion.content.split('\n').length }
                      />
                    )}
                  </div>
                </div>
                  <div className="md:my-4 my-8 flex flex-col gap-4">
                    <label className="text-sm text-semibold text-justify">
                      <input
                        className="mx-2"
                        type="checkbox"
                        checked={checked}
                        onChange={onBlockNotificationEventChange}
                      />
                      Block email notification for new replies.
                    </label>
                  </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setComment("");
                    setSubmittedComment(comment);
                    handleAnswerSubmissionByButton();
                  }}
                >
                  <textarea
                    placeholder="Type answer here..."
                    className="py-4 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-lg w-full my-4 bg-gray-g8"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    onKeyDown={handleAnswerSubmission}
                    rows={comment.split('\n').length > 10 ? 10 : comment.split('\n').length }
                  />
                  <div className="flex justify-end">
                    <input type="file" className="hidden" id="fileInput" onChange={handleFileSelect} />
                    <label htmlFor="fileInput" className="cursor-pointer py-2 px-4 mx-2 rounded-full bg-blue-2 text-white text-xs font-semibold hover:bg-blue-400">
                      {selectedFile? selectedFile.name : 'Upload a file'}
                    </label>
                    <input type="submit" value="Add answer" className="px-2 py-1 bg-green-g1 text-xs float-right rounded-full font-semibold text-white cursor-pointer"
                    />
                  </div>
                </form>

                {/* Answers */}
                <div className="font-bold text-lg">Answers</div>
                {answers.length > 0 &&
                  answers.map(({ answer, creator }, index) => (
                    <div key={`discussion-answer-${index}`} className="mb-4">
                      <div className={`my-4 flex flex-col rounded-lg bg-gray-g8 mt-8 ${activeParentId === answer.id ? 'border border-green-300' : ''}`}>
                        {/* Answer Header */}
                        <div className="flex justify-between gap-4 items-center cursor-pointer rounded-t-lg px-4 py-2 bg-gray-g8"
                          onClick={() => {
                            setIsEditComment(false)
                            setactiveParentId(answer.id)
                            setActiveChildId(answer.parent_answer)
                          }}
                        >
                          {/* <img src={Profile} alt="Profile" className="w-8" /> */}
                          <div className="flex gap-4 ">
                            <div className="font-bold">{ creator.username }</div>
                            <div className="text-gray-g11">
                              {moment(answer.created_at).toLocaleString()}
                            </div>
                          </div>
                          <div className="cursor-pointer py-2 px-4 mx-2 rounded-full text-white text-xs font-semibold bg-green-g1 hover:bg-green-400">
                            {activeParentId === answer.id ? `${childAnswers.length} replies`: "View replies"}
                          </div>
                        </div>

                        <div className="border-t border-dotted border-gray-200 mx-4"></div>

                        {/* Answer Edit/Delete buttons */}
                        <div className="flex justify-end px-4 py-2">
                          <div className="flex gap-4 items-center text-sm">
                            {(isAdmin || (activeUserEmail === creator.email)) && (
                              <>
                                {answerIdInEdit === answer.id ? (
                                  <>
                                    <div className="text-green-g1 cursor-pointer" onClick={() => {
                                      handleAnswerChangeSave()
                                    }}>
                                      Save
                                    </div>
                                    <div className="text-red-r1 cursor-pointer" onClick={() => handleAnswerChangeCancel()}>
                                      Cancel
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="text-green-g1 cursor-pointer" onClick={() => {
                                      handleAnswerChange(answer)
                                    }}>
                                      Edit
                                    </div>
                                    <div className="text-red-r1 cursor-pointer" onClick={() => handleAnswerDelete(answer.id)}>
                                      Delete
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {/* Answer body */}
                        <div className="justify-content text-sm px-4 py-2">
                          {answerIdInEdit === answer.id ? (
                            <>
                              <textarea
                                placeholder="Type Answer here..."
                                className="py-2 px-2 ring-1 ring-gray-g6 text-sm focus:outline-none w-full mb-4 text-white bg-gray-g8"
                                value={answer_.content}
                                onChange={(e) => setAnswer_({...answer_, content: e.target.value})}
                                rows={answer_.content.split('\n').length > 10 ? 10 : answer_.content.split('\n').length }
                              />
                              <div className="flex">
                                {answer.file ? (
                                  <div className="mt-2">
                                    <button className="rounded-full cursor-pointer px-2 bg-gray-g13" onClick={()=>handleDownload(answer.file)}>
                                      {answer.file.split('/uploads/')[1]}
                                    </button>
                                  </div>
                                ) : ''}
                                <input type="file" className="hidden" id="editFileInput" onChange={handleEditFileSelect} />
                                <label htmlFor="editFileInput" className="cursor-pointer py-2 px-4 mx-2 rounded-full bg-blue-2 text-white text-xs font-semibold hover:bg-blue-400">
                                  {selectedEditFile? selectedEditFile.name : answer.file ? 'Replace a File' : 'Upload a file'}
                                </label>
                              </div>
                            </>
                          ) : (
                            <>
                              <textarea
                                className="py-2 px-2 border border-gray-200 text-sm focus:outline-none w-full mb-4"
                                value={answer.content}
                                rows={answer.content.split('\n').length > 10 ? 10 : answer.content.split('\n').length }
                                disabled={true}
                              />
                              {answer.file ? (
                                <div className="mt-2">
                                  <button className="rounded-full cursor-pointer px-2 bg-gray-g13" onClick={()=>handleDownload(answer.file)}>
                                    {answer.file.split('/uploads/')[1]}
                                  </button>
                                </div>
                              ) : ''}
                            </>
                          )}
                        </div>
                      </div>

                      {/* Child Answers */}
                      {childAnswers.length > 0 &&
                        answer.id === activeParentId &&
                        <>
                          <div className="flex justify-end">
                            <div className="w-11/12 relative">
                              <img
                                src={curvedArrow}
                                alt=""
                                className="absolute -left-10"
                              />
                              {childAnswers.map((item, index) => (
                                <div className="flex flex-col mb-2 rounded-lg bg-gray-g10"
                                  key={`discussion-child-ans-${index}`}
                                >
                                  <div className="flex justify-between rounded-t-lg px-4 py-2 bg-gray-g13">
                                    <div className="flex gap-4 items-center">
                                      {/* <img src={Profile} alt="Profile" className="w-8" /> */}
                                      <div className="font-bold">{ creator.username }</div>
                                      <div className="text-gray-g11">
                                        {moment(item.answer.created_at).toLocaleString()}
                                      </div>
                                      {/* {activeUserEmail}
                                      {item.creator.email} */}
                                    </div>
                                    {(isAdmin || (activeUserEmail === item.creator.email)) && (
                                      <div className="flex gap-4 items-center text-sm">
                                        <div className="text-green-g1 cursor-pointer" onClick={() => {
                                          setAnswerComment(item.answer.content)
                                          setIsEditComment(true)
                                          setActiveChildId(item.answer.id)
                                        }
                                        }>
                                          Edit
                                        </div>
                                        <div className="text-red-r1 cursor-pointer" onClick={() => handleSubAnswerDelete(item.answer.id)}>
                                          Delete
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="justify-content text-sm px-4 py-2">
                                    <textarea
                                      className="py-2 px-2 border border-gray-200 text-sm focus:outline-none w-full my-4"
                                      value={item.answer.content}
                                      rows={item.answer.content.split('\n').length > 10 ? 10 : item.answer.content.split('\n').length }
                                      disabled={true}
                                    />
                                  </div>
                                  {item.answer.file ? (
                                    <div className="text-sm px-4 py-2">
                                      <button className="rounded-full cursor-pointer px-2 bg-gray-g13" onClick={()=>handleDownload(item.answer.file)}>
                                        {item.answer.file.split('/uploads/')[1]}
                                      </button>
                                    </div>
                                  ) : ''}
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      }

                      {/* Child Answer Editor */}
                      {activeParentId === answer.id && (
                        <form onSubmit={(e) => {
                          e.preventDefault();
                          handleChildAnswerSubmissionByButton();
                        }}
                        >
                          <div className="flex justify-end">
                            <textarea
                              placeholder="Type answer here..."
                              className="w-11/12 py-4 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-lg my-4 bg-gray-g8"
                              value={answerComment}
                              onChange={(e) => setAnswerComment(e.target.value)}
                              onKeyDown={handleChildAnswerSubmission}
                              rows={answerComment.split('\n').length > 10 ? 10 : answerComment.split('\n').length }
                            />
                          </div>
                          <div className="flex justify-end">
                            <input type="file" className="hidden" id="childFileInput" onChange={handleChildFileSelect} />
                            <label htmlFor="childFileInput" className="cursor-pointer py-2 px-4 mx-2 rounded-full bg-blue-2 text-white text-xs font-semibold hover:bg-blue-400">
                              {childSelectedFile? childSelectedFile.name : 'Upload a file'}
                            </label>
                            <input type="submit" value="Add answer" className="px-2 py-1 bg-green-g1 text-xs float-right rounded-full font-semibold text-white cursor-pointer"
                            />
                          </div>
                        </form>
                      )}
                    </div>
                  ))}
                {loadingAnswer && <div className="h-screen text-white">Loading...</div>}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Discussionv;