import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MainContext } from "../../../../App";
import Logout from "./images/Logout.svg";
import Setting from "./images/Setting.svg";

function Items({ currentItems, setActiveUri, setCount, setVantageId, vantageId }) {
  const navigate = useNavigate()
  const context = React.useContext(MainContext);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  const { search } = useLocation()
  const vantageIdInParam = search.includes('vantage=') ? parseInt(search.split('vantage=')[1]) : null;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token} `,
  };
  const updateAccessHistory = async (vantage) => {
    var vantage_ = vantage;
    if (!vantage_.access_history.includes(parseInt(userId))) {
      vantage_.access_history = [...vantage_.access_history, parseInt(userId)];
      try {
        await axios.put(
          `${process.env.REACT_APP_QA_API}/vantage_/${vantage.id}`,
          vantage_,
          { headers }
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleVantageOpen = (vantage, discussions) => {
    navigate(`/${vantage.category.toLowerCase()}`);

    updateAccessHistory(vantage);
    setActiveUri(vantage.content_uri);
    setCount(discussions.length);
    setVantageId(vantage.id);
  };

  useEffect(() => {
    if (context.activeUri === '' || context.activeUri === false) {
      if (currentItems.length > 0) {
        if (vantageIdInParam !== null) {
          const itemInParam = currentItems.filter(item => item.vantage.id === vantageIdInParam)[0];
          setActiveUri(itemInParam.vantage.content_uri);
          setCount(itemInParam.discussions.length);
          setVantageId(itemInParam.vantage.id);
        } else {
          setActiveUri(currentItems[0].vantage.content_uri);
          setCount(currentItems[0].discussions.length);
          setVantageId(currentItems[0].vantage.id);
        }
      }
    }
  }, [currentItems])
  return (
    <div className="py-6 text-white flex flex-col gap-1">
      {currentItems &&
        currentItems.map(({ vantage, discussions }, index) => (
          <div
            className="py-2 px-3 rounded flex justify-between font-bold cursor-pointer hover:shadow"
            key={index}
            onClick={() => handleVantageOpen(vantage, discussions)}
          >
            {vantageIdInParam === null ? (
              <span className={`font-verdana text-xl font-normal ${vantageId === vantage.id ? "text-[#ffa724]" : "text-white"}`} >
                {vantage.title.charAt(0).toUpperCase()}
                {vantage.title.slice(1)}
              </span>
            ) : (
              <span className={`font-verdana text-xl font-normal ${vantageIdInParam === vantage.id ? "text-[#ffa724]" : "text-white"}`} >
                {vantage.title.charAt(0).toUpperCase()}
                {vantage.title.slice(1)}
              </span>
            )}
          </div>
        ))}
    </div>
  );
}

const LeftSideBar = ({ themeData, setActiveUri, setCount, setVantageId, vantageId }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const context = React.useContext(MainContext);

  useEffect(() => {
    const topicType = pathname.split("/")[1];
    if (topicType === "linux") {
      setSelectedTab(0);
      return;
    }
    if (topicType === "python") {
      setSelectedTab(1);
      return;
    }
    if (topicType === "data_science") {
      setSelectedTab(2);
      return;
    }
    if (topicType === "visualization") {
      setSelectedTab(3);
      return;
    }
    if (topicType === "finance_applications") {
      setSelectedTab(4);
      return;
    }
    if (topicType === "settings") {
      setSelectedTab(6);
      return;
    }
  }, [pathname]);
  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    try {
      setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token} `,
      };
      await axios.post(
        `${process.env.REACT_APP_QA_API}/logout`,
        {},
        { headers }
      );
      setLoading(false);
      localStorage.clear();
      context.login.setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="overflow-hidden table-container hidden md:flex sticky h-screen bg-gray-g1 text-white lg:w-7">
        <button
          className="border-solid bg-gray-g8 rounded float-right px-2 text-2xl"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? "»" : "«"}
        </button>
      </div>
      <div
        className={`overflow-hidden table-container hidden md:block sticky h-screen w-[30%] ${
          !isCollapsed ? "lg:w-1/6" : "lg:w-0"
        } bg-gray-g1 text-white`}
      >
        <div className="h-full flex justify-between flex-col border-gray-g8 border-r-2">
          <div
            className={`mx-4 my-8 flex flex-col text-lg vantage-height-1 ${
              isCollapsed && "hidden"
            }`}
          >
            <Items
              currentItems={themeData}
              setActiveUri={setActiveUri}
              setCount={setCount}
              setVantageId={setVantageId}
              vantageId={vantageId}
            />
            <div
              className={`mt-auto ${isCollapsed && "hidden"}`}
            >
              <div
                className={`px-5 py-3 w-full flex gap-6 rounded-full cursor-pointer ${
                  isCollapsed && "hidden"
                }`}
                onClick={handleLogout}
              >
                <img src={Logout} alt="icon" className="w-5" /> Log out
              </div>
              <Link
                to="/settings"
                className={`px-5 py-3 w-full flex gap-6 rounded-full cursor-pointer ${
                  selectedTab === 6 && ""
                }`}
              >
                <img src={Setting} alt="Setting" className="w-5" />
                Settings
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSideBar;
