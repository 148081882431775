import React from "react";
import { Header, Footer } from "../../shared";
import {
  HeroCard,
  OurBenefit,
  Testimonials,
  Price,
  Section,
  Table,
} from "../../local";
import { MainContext } from "../../../App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../../../src/banner.png"
import LineBreak from "./images/linebreak.png"

const LandingPage = () => {
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false)
  const [showSupportModal, setShowSupportModal] = React.useState(false)
  const context =React.useContext(MainContext)
  const is_2fa = localStorage.getItem("is_2fa") === 'true' ? true : false;
 
  const navigate = useNavigate()
  const currentURL = window.location.pathname

  useEffect(() => {
    if (is_2fa && !context.is2faVerified && context.login.isLoggedIn) {
      if (context.isNewDevice === false) {
        context.setIs2faVerified(true)
        localStorage.setItem('is_2fa_verified', true)
        navigate(context.isVantage === true ? "/linux" : "/statistics")
      } else {
        if (context.isLoginLimited === true) {
          context.setLoginLimitedModal(true)
          localStorage.clear()
          context.login.setIsLoggedIn(false)
        } else {
          context.setVerifyOTPModal(true)
        }
      }
    }
  }, [currentURL, context.login.isLoggedIn])
  return (
    <>
      <div className="">
        <Header
          className="relative"
          login={context.login}
          loginModal={context.loginModal}
          setLoginModal={context.setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
          verifyOTPModal={context.verifyOTPModal}
          setVerifyOTPModal={context.setVerifyOTPModal}
          LoginLimitedModal={context.isLoginLimited}
          setLoginLimitedModal={context.setLoginLimitedModal}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
          showSupportModal={showSupportModal}
          setShowSupportModal={setShowSupportModal}
        />
        <div className="top-14 relative">
          <img src={Banner} className="object-cover w-full h-auto " />
        </div>
      </div>
      <Section />
      <div className="justify-center items-center w-full px-16 block mt-8 rounded">
        <img src={LineBreak} className="w-full" />
      </div>
      <OurBenefit />
      <Testimonials />
      <Price />
      <Footer
        setShowTermsModal={setShowTermsModal}
        setShowSupportModal={setShowSupportModal}
      />
    </>
  );
};

export default LandingPage;

