import React from "react";
import Person from "./images/markus-circle.png";
import symb from "./images/1.svg";
import Slider from "react-slick";


const arr = [
  {
    index: 1,
    url: 'https://markus.scholar.princeton.edu/',
    avatar: Person,
    title: 'Director of the Bendheim Center for Finance, Princeton University',
    name: 'Professor Markus K. Brunnermeier',
    testimonial: 'QuantFinancePrep gives you an edge in quant-finance job interviews by exposing you to the most up to date concepts, codes, and interview questions in finance. It is a must have resource for anyone searching for a job in quant-finance'
  },
  {
    index: 2,
    url: '#',
    avatar: null,
    title: null,
    name: 'T. Lu',
    testimonial: 'QuantFinancePrep has helped me in preparing the essential and precise topics that are covered in my interviews for a quantitative researcher'
  },
  {
    index: 3,
    url: '#',
    avatar: null,
    title: null,
    name: 'J. Li',
    testimonial: 'I really appreciate all the help QuantFinancePrep platform provided to help me succeed in the interview process. Looking back, there are quite a few interview scenarios where if I had not done reading into QuantFinancePrep, I would not be able to figure out the questions (or at least not in the most optimal way). Big thanks!!'
  }
];

const Testimonials = () => {
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', right: 0, marginRight: '50px' }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', left: 0, zIndex: 99, marginLeft: '50px' }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          slidesToShow: 1
        },
      },
    ],
  };
  return (

    <div className="my-16">
      <div className="mb-16 mx-auto w-11/12">
        <div className="text-2xl font-bold text-center">Testimonials </div>
      </div>
      <Slider {...settings}>
        {arr.map((item) => (
          <div key={`slider-${item.index}`}>
            <div>
              <a href={`${item.url}`} target={item.url !== '#' ? "_blank" : ""} className="w-11/12 md:w-3/5 mx-auto pb-8 rounded shadow-md flex flex-col gap-2 items-center bg-green-g2">
                <div className="absolute -mt-6 w-16 mx-auto">
                  {item.avatar ? <img src={item.avatar} alt="person" /> : ''}
                </div>
                <div className="mt-12 w-8 mx-auto">
                  <img src={symb} alt="symbol" />
                </div>
                <div className="text-center px-6 md:px-4 text-lg text-white font-semibold italic ">
                  "{item.testimonial}"
                </div>
                <div className="text-center text-gray-g13 font-bold">{item.name}</div>
                <div className="text-center text-gray-g13 font-bold">{item.title ? item.title : ''}</div>
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
