import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

import leftArrow from "./images/leftArrow.svg";

import axios from 'axios';
import { MainContext } from "../../../../App";

const Theme = () => {
  const { search } = useLocation()
  const { topicType, topicId } = useParams()
  const openDiscussion = search.split('discussion=')[1] === 'true' ? true : false

  const [discussion, setDiscussion] = useState(openDiscussion);
  const [createDiscussion, setCreateDiscussion] = useState(false);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [topicData, setTopicData] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const navigate = useNavigate()
  const context =React.useContext(MainContext)

  const apiCall = async () => {
    const token = localStorage.getItem('token')
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token} `
    }
    try {
      setLoading(true)
      if (token) {
        const { data } = await axios.get(`${process.env.REACT_APP_QA_API}/topic/${topicId}`, { headers })
        setLoading(false)
        setTopicData(data)
      } else {
        navigate('/')
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
      if (error.response.status === 401) {
        context.setIsLoggedIn(false)
        localStorage.clear()
        navigate('/')
      }
      setError('Some error occured. Please reload your page.')
      navigate('/404')
    }
  }
  useEffect(() => {
    apiCall()
  }, [])
  let count = 0;
  topicData.forEach((el) => { count += el.discussions.length })
  return (
    <>
      {loading && <div className="h-screen text-white">Loading...</div>}
      {!loading && <div
        className={`w-full h-screen flex flex-col py-3 md:py-3 md:px-6 overflow-y-scroll ${discussion ? "gap-2 rounded-lg bg-black md:bg-white" : "gap-6 md:gap-12 "
          }`}
      >
        {topicData.map(({ discussions, topic }, index) => {
          return <div key={`topic-${index}`}>

            <div className={`flex items-center gap-2 md:gap-4 text-2xl font-bold text-gray-g1 ${discussion && "hidden md:flex"}`}>
              <img
                src={leftArrow}
                alt="left"
                className="p-2 hover:bg-gray-g10 rounded-full cursor-pointer"
                onClick={() => navigate(-1)}
              />
              {topic.category}
            </div>

            <div className="w-full h-full flex items-center flex-col gap-6 md:gap-8 mx-auto py-8 md:py-10 lg:py-16 rounded bg-gray-g10">
              <div className="w-11/12 md:w-1/2 text-center text-2xl lg:text-4xl font-bold lg:font-semibold">
                {topic.title}
              </div>
              {discussions.map((item, index) => (
                <div className="w-11/12 md:w-3/5 lg:w-[30%] px-4 py-2 bg-white flex items-center gap-4 rounded-full font-medium hover:ring-1 ring-green-g1" key={`radio-${index}`}>
                  <input type="radio" id={index} name='topic' value={selectedIndex} onChange={() => setSelectedIndex(item.id)} className='cursor-pointer' />
                  <label for={index} className='cursor-pointer'>{item.content}</label>
                </div>
              ))}
              {selectedIndex !== 0 && <div className="w-11/12 md:w-3/5 lg:w-[30%] flex justify-center md:justify-end">
                <div className="w-fit px-8 py-2 font-medium text-white rounded-full bg-green-g1 cursor-pointer" onClick={() => navigate(`/${topicType}/discussion/${topicId}/${selectedIndex}`)}>
                  Next
                </div>
              </div>}
            </div>
          </div>
        })}
        {topicId && <Link to={`/discussions?topic=${topicId}`}>
          <div
            className={`mx-2 z-3 md:flex justify-end items-center font-bold cursor-pointer hidden md:absolute md:bottom-4 md:right-4`}
          >
            <div className="mx-2 flex justify-end items-center font-bold cursor-pointer ">
              <div className="py-2 px-4 rounded-full shadow-lg">
                <span>Discussion</span>
                <span className="ml-4 px-4 py-1 text-white bg-green-g1 rounded-full">{count}</span>
              </div>
            </div>
          </div>
          <div
            className={`mx-2 z-3 flex justify-center absolute left-0 right-0 bottom-[2rem] items-center font-bold cursor-pointer md:hidden`}
          >
            <div className="mx-2 flex justify-end items-center font-bold cursor-pointer ">
              <div className="py-2 px-4 rounded-full shadow-lg">
                <span>Discussion</span>
                <span className="ml-4 px-4 py-1 text-white bg-green-g1 rounded-full">{count}</span>
              </div>
            </div>
          </div>
        </Link>}
      </div>}
      {createDiscussion && (
        <CreateDiscussionModal setCreateDiscussion={setCreateDiscussion} />
      )}
    </>
  );
};

export default Theme;

const CreateDiscussionModal = ({ setCreateDiscussion }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}

          <div className="px-2 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
            {/*header*/}

            <div className="m-4 flex flex-col gap-6">
              <div className="flex justify-between">
                <span className="font-bold">Ask your question</span>
                <span
                  className="font-semibold text-gray-g4 cursor-pointer"
                  onClick={() => setCreateDiscussion(false)}
                >
                  X
                </span>
              </div>
              <textarea
                placeholder="Type here.."
                rows={6}
                className="w-96 p-2 text-sm rounded ring-1 ring-gray-g6 placeholder-text-gray-g2 focus:outline-none"
              ></textarea>
              <div className='flex justify-end'>
                <div className=" px-6 p y-1 rounded-full bg-green-g1 text-white cursor-pointer">
                  Send
                </div>
              </div>
            </div>

            {/* header end */}
          </div>
        </div>
      </div>
      <div className="opacity-90 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
