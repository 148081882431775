import React from "react";

import { TopBar, LeftSideBar } from "../../vantage";
import 'react-toastify/dist/ReactToastify.css'
import { MainContext } from "../../../App";


const Dashboard = ({children}) => {
  const [open, setOpen] = React.useState(false)
  const context =React.useContext(MainContext)

  return (
    <>
      <div className="md:flex flex-col">
        <TopBar open={open} setOpen={setOpen} />
        <div className="flex flex-row table-container">
          <LeftSideBar themeData={context.vantageThemeData} setActiveUri={context.setActiveUri} setCount={context.setVantageCount} setVantageId={context.setVantageId} vantageId={context.vantageId} />
          {!open && <>{children}</>}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
