import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "./components/local/landing/LandingPage";
import Dashboard from "./components/local/dashboard/Dashboard";
import ConfirmationPage from "./components/local/confirmation/ConfirmationPage";
import _404Page from "./components/local/_404/_404Page";
import {
  Theme,
  Setting,
  Discussion,
  DiscussionList,
  Table
} from "./components/local";
import ResetPasswordPage from "./components/local/reset_password/ResetPasswordPage";
import MonthlyInsights from "./components/local/monthly_insights/MonthlyInsights";
import AtAGlance from "./components/local/at_a_galance/AtAGlance";
import SuccessStories from "./components/local/success_stories/SuccessStories";


import Dashboardv from "./components/vantage/dashboard/Dashboard";
import {
  Themev,
  Discussionv,
  DiscussionListv,
  Tablev
} from "./components/vantage";
import Vantage from "./components/local/vantage/Vantage";


export const MainContext = React.createContext()

export default function App() {
  const token = localStorage.getItem("token")
  const [isLoggedIn, setIsLoggedIn] = useState(token ? true : false);
  const [activeUri, setActiveUri] = useState('')
  const login = {isLoggedIn, setIsLoggedIn}
  const [loginModal, setLoginModal] = React.useState(false);
  const [verifyOTPModal, setVerifyOTPModal] = React.useState(false);
  const [loginLimitedModal, setLoginLimitedModal] = React.useState(false);
  const [showLegalDisclaimerModal, setShowLegalDisclaimerModal] = React.useState(false);
  const [isNewDevice, setIsNewDevice] = useState(localStorage.getItem("is_new_device"))

  const is_2fa = localStorage.getItem("is_2fa") === 'true' ? true : false;
  const [is2faVerified, setIs2faVerified] = useState(localStorage.getItem("is_2fa_verified") === 'true' ? true : false)
  const [isSubscription, setIsSubscription] = useState(localStorage.getItem("is_subscribed") === 'true' ? true : false);
  const [isVantageSubscription, setIsVantageSubscription] = useState(localStorage.getItem("is_vantage_subscribed") === 'true' ? true : false);
  const [isVantage, setIsVantage] = useState(localStorage.getItem("is_vantage") === 'true' ? true : false);
  const [vantageThemeData, setVantageThemeData] = useState([])
  const [vantageId, setVantageId] = useState('')
  const [vantageCount, setVantageCount] = useState(0)


  const [isAdmin, setIsAdmin] = useState(localStorage.getItem("is_admin") === 'true' ? true : false)
  const [isLoginLimited, setIsLoginLimited] = useState(false)
  useEffect(() => {
    if (!token) {
      localStorage.clear()
    }
  }, []);
  return (
    <>
    <MainContext.Provider value={{
      login, 
      setIsLoggedIn, 
      activeUri, 
      setActiveUri, 
      loginModal, 
      setLoginModal,
      verifyOTPModal,
      setVerifyOTPModal,
      isSubscription,
      setIsSubscription,
      isVantageSubscription,
      setIsVantageSubscription,
      isVantage,
      setIsVantage,
      vantageThemeData,
      setVantageThemeData,
      vantageId,
      setVantageId,
      vantageCount,
      setVantageCount,
      isAdmin,
      setIsAdmin,
      isNewDevice,
      setIsNewDevice,
      is2faVerified,
      setIs2faVerified,
      isLoginLimited,
      setIsLoginLimited,
      loginLimitedModal,
      setLoginLimitedModal,
      showLegalDisclaimerModal,
      setShowLegalDisclaimerModal
      }}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/confirmation/:token/:username" element={< ConfirmationPage/>} />
          <Route exact path="/reset-password/:token/:username" element={< ResetPasswordPage/>} />
          {is_2fa && is2faVerified && isLoggedIn && isSubscription && !isVantage && (
            <>
              <Route
                path="statistics"
                element={
                  <Dashboard>
                    <Table />
                  </Dashboard>
                }
              />
              <Route
                path="finance"
                element={
                  <Dashboard>
                    <Table />
                  </Dashboard>
                }
              />
              <Route
                path="coding"
                element={
                  <Dashboard>
                    <Table />
                  </Dashboard>
                }
              />
              <Route
                path="ml"
                element={
                  <Dashboard>
                    <Table />
                  </Dashboard>
                }
              />
              <Route
                path=":topicType/:topicId"
                element={
                  <Dashboard>
                    <Theme />
                  </Dashboard>
                }
              />
              <Route
                path="settings"
                element={
                  <Dashboard>
                    <Setting />
                  </Dashboard>
                }
              />
              <Route
                path="discussions"
                element={
                  <Dashboard>
                    <DiscussionList />
                  </Dashboard>
                }
              />
              <Route
                path=":topicType/discussion/:topicId/:discussionId"
                element={
                  <Dashboard>
                    <Discussion />
                  </Dashboard>
                }
              />
            </>
          )}
          {is_2fa && is2faVerified && isLoggedIn && isVantageSubscription && isVantage && (
            <>
              <Route
                path="linux"
                element={
                  <Dashboardv>
                    <Tablev />
                  </Dashboardv>
                }
              />
              <Route
                path="python"
                element={
                  <Dashboardv>
                    <Tablev />
                  </Dashboardv>
                }
              />
              <Route
                path="data_science"
                element={
                  <Dashboardv>
                    <Tablev />
                  </Dashboardv>
                }
              />
              <Route
                path="visualization"
                element={
                  <Dashboardv>
                    <Tablev />
                  </Dashboardv>
                }
              />
              <Route
                path="finance_applications"
                element={
                  <Dashboardv>
                    <Tablev />
                  </Dashboardv>
                }
              />
              <Route
                path=":topicType/:topicId"
                element={
                  <Dashboardv>
                    <Themev />
                  </Dashboardv>
                }
              />
              <Route
                path="settings"
                element={
                  <Dashboardv>
                    <Setting />
                  </Dashboardv>
                }
              />
              <Route
                path="discussions"
                element={
                  <Dashboardv>
                    <DiscussionListv />
                  </Dashboardv>
                }
              />
              <Route
                path=":topicType/discussion/:topicId/:discussionId"
                element={
                  <Dashboardv>
                    <Discussionv />
                  </Dashboardv>
                }
              />
            </>
          )}
          {is_2fa && is2faVerified && isLoggedIn && !isSubscription && (
            <>
              <Route
                path="settings"
                element={
                  <Dashboard>
                    <Setting />
                  </Dashboard>
                }
              />
              <Route path="*"
                 element={
                  <Dashboard>
                    <Setting />
                  </Dashboard>
                }
              />
            </>
          )}
          {is_2fa && is2faVerified && isLoggedIn && !isVantageSubscription && (
            <>
              <Route
                path="settings"
                element={
                  <Dashboardv>
                    <Setting />
                  </Dashboardv>
                }
              />
              <Route path="*"
                 element={
                  <Dashboardv>
                    <Setting />
                  </Dashboardv>
                }
              />
            </>
          )}
          {is_2fa && !is2faVerified && isLoggedIn && (
            <Route path="*" element={(<Navigate replace to="/" />)} />
          )}
          {is_2fa && is2faVerified && isLoggedIn && (
            <Route path="/" element={(<Navigate replace to={isVantage === true ? "/linux" : "/statistics"} />)} />
          )}
          <Route path="/qfp_insights" element={<MonthlyInsights />} />
          <Route path="/at_a_glance" element={<AtAGlance />} />
          <Route path="/vantage" element={<Vantage />} />
          <Route path="/success_stories" element={<SuccessStories />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={(<Navigate replace to="/404" />)} />
          <Route path="/404" element={<_404Page />} />
        </Routes>
      </BrowserRouter>
    </MainContext.Provider>
    </>
  );
}
