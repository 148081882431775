import React from "react";
import { Header, Footer } from "../../shared";
import { MainContext } from "../../../App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const MonthlyInsights = () => {
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const [showSupportModal, setShowSupportModal] = React.useState(false);
  const [monthlyInsights, setMonthlyInsights] = React.useState(null);
  const [monthlyInsightId, setMonthlyInsightId] = React.useState(-1);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const context = React.useContext(MainContext);
  const is_2fa = localStorage.getItem("is_2fa") === "true" ? true : false;

  const navigate = useNavigate();
  const currentURL = window.location.pathname;

  useEffect(() => {
    if (is_2fa && !context.is2faVerified && context.login.isLoggedIn) {
      if (context.isNewDevice === false) {
        context.setIs2faVerified(true);
        localStorage.setItem("is_2fa_verified", true);
        navigate(context.isVantage === true ? "/linux" : "/statistics");
      } else {
        if (context.isLoginLimited === true) {
          context.setLoginLimitedModal(true);
          localStorage.clear();
          context.login.setIsLoggedIn(false);
        } else {
          context.setVerifyOTPModal(true);
        }
      }
    }
  }, [currentURL, context.login.isLoggedIn]);

  const loadMonthlyInsights = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_QA_API}/monthly-insight`
    );
    setMonthlyInsights(data.monthly_insights);
    if (data.monthly_insights.length > 0) {
      setMonthlyInsightId(0);
    }
  };

  useEffect(() => {
    loadMonthlyInsights();
  }, []);

  useEffect(() => {
    if (monthlyInsightId !== -1 && monthlyInsights !== null) setMenuOpen(false);
  }, [monthlyInsightId]);

  const onClickMonthlyInsight = async (idx) => {
    setMenuOpen(false);
    setMonthlyInsightId(idx);
  };
  return (
    <>
      <div className="">
        <Header
          className="relative"
          login={context.login}
          loginModal={context.loginModal}
          setLoginModal={context.setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
          verifyOTPModal={context.verifyOTPModal}
          setVerifyOTPModal={context.setVerifyOTPModal}
          LoginLimitedModal={context.isLoginLimited}
          setLoginLimitedModal={context.setLoginLimitedModal}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
          showSupportModal={showSupportModal}
          setShowSupportModal={setShowSupportModal}
        />
      </div>
      <div className="flex flex-col article-section px-8">
        <div className="py-12 text-gray-g1 flex flex-col gap-1"></div>
        <div className="text-2xl font-bold flex">
          <a href="/qfp_insights">QFP Insights</a>
          <div class="block md:hidden text-white lg:w-7">
            <button
              class="border-solid bg-gray-g8 rounded float-right px-2 text-2xl ml-2"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {!menuOpen ? "»" : "«"}
            </button>
          </div>
        </div>
        <div className="flex h-[75vh] mt-4">
          <div className="hidden md:block md:w-1/5 bg-gray-g5 px-2 py-2">
            {monthlyInsights
              ? monthlyInsights.map((newsletter, index) => (
                  <div
                    key={`title-${index}`}
                    onClick={() => onClickMonthlyInsight(index)}
                    className="py-2 px-3 rounded flex justify-between font-bold cursor-pointer hover:shadow text-gray-1 hover:text-green-g1"
                  >
                    <span className="font-verdana text-xl font-normal">
                      {newsletter.title}
                    </span>
                  </div>
                ))
              : ""}
          </div>

          {menuOpen ? (
            <div className="w-4/5 bg-gray-g5 px-2 py-2 h-[75vh] absolute">
              {monthlyInsights
                ? monthlyInsights.map((newsletter, index) => (
                    <div
                      key={`title-${index}`}
                      onClick={() => onClickMonthlyInsight(index)}
                      className="py-2 px-3 rounded flex justify-between font-bold cursor-pointer hover:shadow text-gray-1 hover:text-green-g1"
                    >
                      <span className="font-verdana text-xl font-normal">
                        {newsletter.title}
                      </span>
                    </div>
                  ))
                : ""}
            </div>
          ) : (
            ""
          )}
          <div className="w-full md:w-4/5">
            {monthlyInsightId === -1 ? (
              ""
            ) : (
              <div className="px-0 md:px-4 text-gray-g1 flex flex-col gap-1">
                <iframe
                  srcDoc={`${monthlyInsights[monthlyInsightId].html_file}`}
                  title={monthlyInsights[monthlyInsightId].title}
                  width="100%"
                  className="h-[73vh]"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer
        setShowTermsModal={setShowTermsModal}
        setShowSupportModal={setShowSupportModal}
      />
    </>
  );
};

export default MonthlyInsights;
