import React from "react";
import { Header, Footer } from "../../shared";
import { MainContext } from "../../../App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AtAGlance = () => {
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const [showSupportModal, setShowSupportModal] = React.useState(false);
  const [canvaUri, setCanvaUri] = React.useState(null);
  const context = React.useContext(MainContext);
  const is_2fa = localStorage.getItem("is_2fa") === "true" ? true : false;

  const navigate = useNavigate();
  const currentURL = window.location.pathname;

  useEffect(() => {
    if (is_2fa && !context.is2faVerified && context.login.isLoggedIn) {
      if (context.isNewDevice === false) {
        context.setIs2faVerified(true);
        localStorage.setItem("is_2fa_verified", true);
        navigate(context.isVantage === true ? "/linux" : "/statistics");
      } else {
        if (context.isLoginLimited === true) {
          context.setLoginLimitedModal(true);
          localStorage.clear();
          context.login.setIsLoggedIn(false);
        } else {
          context.setVerifyOTPModal(true);
        }
      }
    }
  }, [currentURL, context.login.isLoggedIn]);

  const loadAtAGlance = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_QA_API}/at-a-glance`
    );
    setCanvaUri(data.canva_uri);
  };

  useEffect(() => {
    loadAtAGlance();
  }, []);

  return (
    <>
      <div className="">
        <Header
          className="relative"
          login={context.login}
          loginModal={context.loginModal}
          setLoginModal={context.setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
          verifyOTPModal={context.verifyOTPModal}
          setVerifyOTPModal={context.setVerifyOTPModal}
          LoginLimitedModal={context.isLoginLimited}
          setLoginLimitedModal={context.setLoginLimitedModal}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
          showSupportModal={showSupportModal}
          setShowSupportModal={setShowSupportModal}
        />
      </div>
      <div className="flex flex-col article-section px-8">
        <div className="py-12 text-gray-g1 flex flex-col gap-1"></div>
        <div className="text-2xl font-bold">
          <a href="/at_a_glance">At a Glance</a>
        </div>
        <div className="text-gray-g1 flex flex-col gap-1 w-full md:w-[80%] m-auto">
          {canvaUri && (
            <div className="relative w-full h-0 pb-[56.2225%] shadow-md mt-8 mb-8 overflow-hidden rounded-md">
              <iframe
                className="absolute w-full h-full top-0 left-0 border-none p-0 m-0"
                src={canvaUri}
                allowFullScreen
                allow="fullscreen"
                loading="lazy"
                title="At a Glance"
              ></iframe>
            </div>
          )}
        </div>
      </div>
      <Footer
        setShowTermsModal={setShowTermsModal}
        setShowSupportModal={setShowSupportModal}
      />
    </>
  );
};

export default AtAGlance;
